<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách danh mục sản phẩm'">
      <template v-slot:toolbar>
        <b-row>
          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder"
            v-show="checkPermission('CATE_INSERT')"
            @click="showModalAddCate"
          >
            <i class="flaticon2-add-1 icon-size-1-rem"></i>
            Tạo mới
          </b-button>
        </b-row>
      </template>
      <template v-slot:preview>
        <div class="mb-5">
          <b-form>
            <div class="row">
              <b-col cols="8">
                <div class="row">
                  <b-col md="4" class="mr-4">
                    <b-input
                      size="sm"
                      placeholder="Tìm kiếm theo tên"
                      v-model="searchName"
                      v-on:keyup.enter="fetchData"
                      autocomplete="off"
                    />
                  </b-col>
                  <b-col md="4" class="mr-4">
                    <b-input
                      size="sm"
                      placeholder="Tìm kiếm theo mã"
                      v-model="searchCode"
                      v-on:keyup.enter="fetchData"
                      autocomplete="off"
                    />
                  </b-col>
                  <b-col md="1">
                    <b-button
                      size="sm"
                      variant="primary"
                      style="width: 70px"
                      @click="onFilter"
                      >Lọc</b-button
                    >
                  </b-col>
                </div>
              </b-col>
            </div>
          </b-form>
        </div>

        <b-modal ref="add-cate-modal" hide-footer title="Thêm mới danh mục">
          <v-form v-model="valid" lazy-validation>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <b-form-select
                  size="sm"
                  v-model="selectedParent"
                  :options="listCate"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >-- Chọn danh mục cha --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Tên:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập tên"
                  v-model="name"
                  :state="validateState('name')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.name.required"
                  >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Mã:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập mã"
                  v-model="code"
                  :state="validateState('code')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.code.required"
                  >Yêu cầu nhập mã danh mục</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <b-button
              size="sm"
              variant="primary"
              class="mr-3"
              style="width: 70px"
              v-show="checkPermission('CATE_INSERT')"
              @click="createCategory"
              >Lưu</b-button
            >
            <b-button
              size="sm"
              variant="secondary"
              style="width: 70px"
              @click="hideModalAddCate"
              >Hủy</b-button
            >
          </v-form>
        </b-modal>
        <b-modal ref="update-cate-modal" hide-footer title="Cập nhật danh mục">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <b-form-select
                  size="sm"
                  v-model="selectedParent"
                  :options="listCate"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >-- Chọn danh mục cha --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Tên:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập tên"
                  v-model="name"
                  :state="validateState('name')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.name.required"
                  >Yêu cầu nhập tên danh mục</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Mã:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập mã"
                  v-model="code"
                  :state="validateState('code')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.code.required"
                  >Yêu cầu nhập mã danh mục</b-form-invalid-feedback
                >
              </b-form-group>
            </div>

            <b-button
              size="sm"
              variant="primary"
              class="mr-3"
              style="width: 70px"
              @click="updateCategory"
              v-show="checkPermission('CATE_UPDATE')"
              >Lưu</b-button
            >
            <b-button
              style="width: 70px"
              variant="secondary"
              size="sm"
              @click="hideModalUpdateCate"
              >Hủy</b-button
            >
          </v-form>
        </b-modal>
        <b-modal
          ref="merge-tree-cate-modal"
          hide-footer
          title="Di chuyển cây danh mục"
        >
          <v-form>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <b-form-select
                  size="sm"
                  v-model="targetCateToBeMerged"
                  :options="listMergeTreeCate"
                  value-field="id"
                  text-field="name"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >-- Chọn danh mục cha --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>

              <b-button
                size="sm"
                variant="primary"
                class="mr-3"
                style="width: 130px"
                @click="mergeTreeCategory"
                >Di chuyển tới đây</b-button
              >
              <b-button
                style="width: 70px"
                variant="secondary"
                size="sm"
                @click="hideMergeTreeModal"
                >Hủy</b-button
              >
            </div>
          </v-form>
        </b-modal>
        <b-modal ref="merge-cate-modal" hide-footer title="Gộp danh mục">
          <div class="d-flex">
            <div class="w-50">
              <b-form-group>
                <label>Từ danh mục:</label>
                <p>
                  <b> {{ categoryMerge.picked.name }}</b>
                </p>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <b-form-group>
                  <label>Đến danh mục:</label>
                  <b-form-select
                    size="sm"
                    v-model="categoryMerge.mergedId"
                    :options="listMergeTreeCate"
                    @change="onChangeMergeCate"
                    value-field="id"
                    text-field="name"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >Chọn danh mục</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-form-group>
            </div>
          </div>

          <b-button
            size="sm"
            variant="primary"
            class="mr-3"
            style="width: 70px"
            @click="onMergeCate"
            v-show="checkPermission('CATE_UPDATE')"
            >Lưu</b-button
          >
          <b-button
            style="width: 70px"
            variant="secondary"
            size="sm"
            @click="$refs['merge-cate-modal'].hide()"
            >Hủy</b-button
          >
        </b-modal>
        <div ref="loadMoreCategory">
          <b-table
            :items="listItem"
            :fields="fields"
            bordered
            hover
            :busy="onLoading"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="6"></vcl-table>
            </template>
            <template v-slot:cell(stt)="row">
              <div class="d-flex justify-content-center w-6">
                <span v-text="row.item.stt"></span>
              </div>
            </template>
            <template v-slot:cell(name)="row">
              <div v-html="formatCellByLevel(row.item, 'name')"></div>
            </template>
            <template v-slot:cell(code)="row">
              <div v-html="formatCellByLevel(row.item, 'code')"></div>
            </template>
            <template v-slot:cell(actions)="row">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" no-caret right lazy>
                  <template slot="button-content">
                    <i class="flaticon2-settings icon-size-1-rem pr-0"></i>
                  </template>
                  <b-dropdown-item
                    @click="editItem(row.item)"
                    v-if="checkViewOnly()"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i class="flaticon2-box-1 icon-size-1-rem"></i>
                      &nbsp; Chi tiết
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="editItem(row.item)"
                    v-if="!checkViewOnly()"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i class="flaticon2-pen icon-size-1-rem"></i>
                      &nbsp; Chỉnh sửa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="popupMergeCate(row.item)"
                    v-if="checkPermission('CATE_MERGE')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        class="fas fa-layer-group text-warning icon-size-1-rem"
                      ></i>
                      &nbsp; Gộp danh mục
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showMergeTreeModal(row.item)"
                    v-show="checkPermission('CATE_MOVE')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="color: #8908fd"
                        class="flaticon-arrows icon-size-1-rem"
                      ></i>
                      &nbsp; Di chuyển danh mục
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="addChildCate(row.item)"
                    v-show="checkPermission('CATE_INSERT')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="color: #1bc5bc"
                        class="fas fa-plus icon-size-1-rem"
                      ></i>
                      &nbsp; Thêm danh mục con
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showDeleteAlert(row.item)"
                    v-show="checkPermission('CATE_DELETE')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="color: #d33"
                        class="
                          flaticon2-rubbish-bin-delete-button
                          icon-size-1-rem
                        "
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số:
                {{ totalRow }}
              </p>
            </b-col>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.icon-size-1-rem {
  font-size: 1rem !important;
}
.icon {
  padding-top: 7px;
}
.ml-10 {
  margin-left: 5px;
}
.ml-20 {
  margin-left: 10px;
}
.ml-30 {
  margin-left: 15px;
}
.ml-40 {
  margin-left: 20px;
}
.ml-50 {
  margin-left: 25px;
}
.sttCateClass {
  width: 6%;
}
.nameCateClass {
  width: 45%;
}
.codeCateClass {
  width: 45%;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.container-fluid {
  height: 100%;
  display: table;
  width: 100%;
  padding: 0;
}

.row-fluid {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.centering {
  float: none;
  margin: 0 auto;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { checkPermission } from '../../../utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import { required } from 'vuelidate/lib/validators';
import { chunk } from 'lodash';
import { getDeepByDescendant, getDescendantCategories } from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      searchName: '',
      searchCode: '',
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          thStyle: { textAlign: 'center', fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên danh mục',
          sortable: false,
          tdClass: 'nameCateClass',
          thClass: 'nameCateClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'code',
          label: 'Mã danh mục',
          sortable: false,
          tdClass: 'codeCateClass',
          thClass: 'codeCateClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      listItem: [],
      page: 1,
      count: 1,
      listCate: [],
      selectedParent: null,
      listMergeTreeCate: [],
      targetCateToBeMerged: null,
      cateToBeMerged: null,
      name: '',
      code: '',
      valid: true,
      cateId: 0,
      totalRow: 0,
      onLoading: false,
      submitted: false,
      isNew: true,
      chunkData: [],
      isLoadMore: false,
      categoryMerges: [],
      categoryMerge: {
        picked: {},
        merged: {},
        mergedId: null,
      },
    };
  },
  components: {
    KTCodePreview,
    VclTable,
  },
  validations: {
    name: {
      required,
    },
    code: {
      required,
    },
  },
  created() {
    this.getListCate();
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh mục sản phẩm', route: '/categories' },
      { title: 'Danh mục sản phẩm' },
    ]);

    this.$nextTick(function () {
      window.addEventListener('scroll', this.onScrollCategory);
      this.onScrollCategory();
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollCategory);
  },
  methods: {
    checkPermission,
    onScrollCategory() {
      if (this.isLoadMore || !this.chunkData.length) return;

      const usersHeading = this.$refs['loadMoreCategory'];
      if (usersHeading) {
        const marginTopUsers = usersHeading.getBoundingClientRect().top;
        const innerHeight = window.innerHeight;
        if (marginTopUsers - innerHeight < -500) {
          this.getDataLoadMore();
        }
      }
    },
    getDataLoadMore() {
      this.isLoadMore = true;
      const nextChunk = this.chunkData.splice(0, 1);
      nextChunk[0].forEach((element) => {
        let item = {
          id: element.id,
          stt: this.count,
          name: element.name,
          code: element.code,
          level: element.level,
          parentId: element.parentId,
        };
        this.listItem.push(item);
        this.count++;
        this.isLoadMore = false;
      });
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    isValidData(data) {
      var format = /[`!@#$%^&*()+\-={};':"|,.<>?~]/;
      return !format.test(data);
    },
    formatCellByLevel(category, field) {
      let tagHtml = '';
      switch (category.level) {
        case 0:
          tagHtml = `<p >${category[field]}</p>`;
          break;
        case 1:
          tagHtml = `<p class="ml-10">${category[field]}</p>`;
          break;
        case 2:
          tagHtml = `<p class="ml-20">${category[field]}</p>`;
          break;
        case 3:
          tagHtml = `<p class="ml-30">${category[field]}</p>`;
          break;
        case 4:
          tagHtml = `<p class="ml-40">${category[field]}</p>`;
          break;
        case 5:
          tagHtml = `<p class="ml-50">${category[field]}</p>`;
          break;
        default:
          break;
      }
      return tagHtml;
    },
    getListCate: function () {
      this.listCate = [];
      let param = {
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.totalRow = data.data.total_row;
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '-' + element.name;
          } else if (element.level === 2) {
            name = '--' + element.name;
          } else if (element.level === 3) {
            name = '---' + element.name;
          } else if (element.level === 4) {
            name = '----' + element.name;
          } else if (element.level === 5) {
            name = '-----' + element.name;
          } else if (element.level === 6) {
            name = '------' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            name: name,
            level: element.level,
            parentId: element.parentId,
          };
          this.listCate.push(item);
        });
        this.$nprogress.done();
        this.$nprogress.remove();
      });
    },
    onFilter() {
      this.fetchData();
    },
    fetchData: async function () {
      this.$nprogress.remove();
      const param = {
        name: this.searchName,
        code: this.searchCode,
      };
      let paramQuery = {
        params: param,
      };
      this.onLoading = true;
      ApiService.setHeader();
      ApiService.query('category', paramQuery)
        .then(async ({ data }) => {
          this.count = 1;
          this.totalRow = data.data.total_row;
          this.listItem = [];
          const categories = data.data.list_caterogy;
          this.categoryMerges = [...categories];
          const firstChunk = categories.splice(0, 50);
          firstChunk.forEach((element) => {
            let item = {
              id: element.id,
              stt: this.count,
              name: element.name,
              code: element.code,
              level: element.level,
              parentId: element.parentId,
            };
            this.listItem.push(item);
            this.count++;
          });

          this.chunkData = categories.length ? chunk(categories, 100) : [];

          this.onLoading = false;
        })
        .catch((err) => {
          this.onLoading = false;
          throw new Error(err);
        });
    },
    checkViewOnly: function () {
      let count = 0;
      if (checkPermission('CATE_VIEW')) {
        count++;
      }
      if (checkPermission('CATE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    deleteItem: async function (item) {
      let id = item.id;
      ApiService.post(`category/delete/${id}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            this.makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            this.makeToastFaile(message);
          }
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa danh mục!',
        text: 'Bạn có chắc muốn xóa danh mục này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    editItem: function (item) {
      this.cateId = item.id;
      this.getCategoryById();
      this.showModalUpdateCate();
    },
    getCategoryById: function () {
      ApiService.setHeader();
      ApiService.get(`category/${this.cateId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.name = data.data.name;
            this.code = data.data.code;
            this.selectedParent = data.data.parentId;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    addChildCate: function (item) {
      this.showModalAddCate();
      this.selectedParent = item.id;
    },
    showModalAddCate() {
      this.isNew = true;
      this.selectedParent = null;
      this.name = '';
      this.code = '';
      this.$refs['add-cate-modal'].show();
    },
    hideModalAddCate() {
      this.$refs['add-cate-modal'].hide();
    },
    showModalUpdateCate() {
      this.isNew = true;
      this.$refs['update-cate-modal'].show();
    },
    hideModalUpdateCate() {
      this.$refs['update-cate-modal'].hide();
    },
    createCategory: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (!this.isValidData(this.name)) {
        alert('Tên danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      if (!this.isValidData(this.code)) {
        alert('Mã danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      const name = this.name ? this.name.trim() : '';
      const code = this.code ? this.code.trim() : '';
      let data = {
        code: code,
        name: name,
        parentId: this.selectedParent,
      };
      ApiService.setHeader();
      ApiService.post('category/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.hideModalAddCate();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    updateCategory: async function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (!this.isValidData(this.name)) {
        alert('Tên danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      if (!this.isValidData(this.code)) {
        alert('Mã danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      const name = this.name ? this.name.trim() : '';
      const code = this.code ? this.code.trim() : '';
      let data = {
        id: this.cateId,
        name: name,
        code: code,
        parentId: this.selectedParent,
      };
      ApiService.setHeader();
      ApiService.post('category/update', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.hideModalUpdateCate();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    showMergeTreeModal(item) {
      this.listMergeTreeCate = [];
      const descendants = getDescendantCategories(item, this.listCate);
      const descendantIds = descendants.map((item) => item.id);
      const deep = getDeepByDescendant(item, descendants);

      this.listCate.forEach((cate) => {
        if (
          cate.id !== item.id &&
          item.parentId !== cate.id &&
          !descendantIds.includes(cate.id) &&
          deep + cate.level < 4
        ) {
          this.listMergeTreeCate.push(cate);
        }
      });

      this.cateToBeMerged = item;
      this.$refs['merge-tree-cate-modal'].show();
    },
    hideMergeTreeModal() {
      this.$refs['merge-tree-cate-modal'].hide();
    },
    mergeTreeCategory() {
      ApiService.post('/category/move', {
        sourceCateId: this.cateToBeMerged.id,
        destCateId: this.targetCateToBeMerged,
      })
        .then((response) => {
          const { data } = response;
          const { status, message } = data;

          if (status === 1) {
            this.makeToastSuccess(message);
            this.fetchData();
            this.getListCate();
            this.hideMergeTreeModal();
          } else {
            this.makeToastFaile(message);
            this.hideMergeTreeModal();
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getListCateFiltered() {
      return this.listCate.slice(10);
    },
    popupMergeCate(category) {
      const parentCategory = this.listCate.find(
        (cate) => cate.id === category.parentId
      );

      if (!parentCategory) {
        this.makeToastFaile('Danh mục cha không tồn tại');
        return;
      }

      this.categoryMerge = {
        picked: category,
        merged: null,
        mergedId: null
      };

      this.listMergeTreeCate = [];

      this.listCate.forEach((cate) => {
        if (cate.parentId === parentCategory.id && cate.id !== category.id) {
          this.listMergeTreeCate.push(cate);
        }
      });
      this.listMergeTreeCate.unshift(parentCategory);
      this.$refs['merge-cate-modal'].show();
    },
    onChangeMergeCate(value) {
      const merged = this.listCate.find((cate) => cate.id === value);
      if (!merged) {
        throw new Error('Danh mục không tồn tại');
      }
      this.categoryMerge.merged = merged;
    },
    onMergeCate() {
      if (!this.categoryMerge.merged) {
        this.makeToastFaile('Chưa chọn danh mục để gôp');
        return;
      }
      const data = {
        ...this.categoryMerge,
      };
      ApiService.put('category/merge', data)
        .then((response) => {
          if (response.data.status === 1) {
            this.makeToastSuccess(response.data.message);
            this.fetchData();
            this.getListCate();
            this.$refs['merge-cate-modal'].hide();
          } else {
            this.makeToastFaile(response.data.message);
          }
        })
        .catch(() => {
          this.makeToastFaile('Thất bại');
        });
    },
  },
};
</script>
